<template>
  <div>
    <img @click="dialogStatus = true" src="@/assets/Images/Frame.svg" />
    <v-dialog v-model="dialogStatus" width="300px">
      <v-card class="text-center">
        <v-color-picker
          hide-mode-switch
          @change="setHexValue()"
          v-model="currentColor"
          mode="hex"
          :disabled="edit"
        ></v-color-picker>
        <v-row class="px-3 justify-center" v-if="type == 'single'" align="center">
          <v-col cols="9">
            <v-btn depressed class="active-btn my-2 mb-2" width="120px" @click="addColor()"
              >Save</v-btn
            >
          </v-col>
          <!-- <v-col cols="5">
            <v-btn depressed class="inactive-btn no-btn my-2 mb-2 ml-2" @click="removeColor()"
              >Delete Shade</v-btn
            >
          </v-col> -->
        </v-row>

        <v-row class="px-3" v-else>
          <v-col cols="5">
            <v-btn depressed class="active-btn my-2 mb-2" @click="addColor()">Add Shade</v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn depressed class="inactive-btn no-btn my-2 mb-2 ml-2" @click="removeColor()"
              >Delete Shade</v-btn
            >
          </v-col></v-row
        >

        <div v-if="model && model.length > 0" class="butler-bold text-maroon font-18 mt-4">
          Selected Colors
        </div>
        <!-- <pre>{{model}}</pre> -->
        <v-row class="py-4 ml-4">
          <v-col
            class="d-flex px-0"
            v-for="(selectedColor, index) in model"
            :key="selectedColor.color"
            col="6"
          >
            <div
              class="rounded-color"
              :class="{ selected: selectedColor.selected }"
              @click="selectColor(selectedColor)"
              :style="colorStyles(selectedColor.color)"
              v-if="selectedColor.color != 'No Embroidery'"
            ></div>
            <div class="ml-3 mt-1">
              {{ model[index].color }}
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import remove from 'lodash/remove';
import each from 'lodash/each';
export default {
  props: ['model', 'category', 'edit', 'type'],
  data() {
    return {
      dialogStatus: false,
      currentColor: 'hex',
      selectedHex: [],
    };
  },

  methods: {
    setHexValue() {
      this.currentColor = this.currentColor.substring(0, 7);
    },
    colorStyles(val) {
      return 'background-color: ' + val;
    },
    addColor() {
      var colorCode = this.currentColor.hex;
      var obj = { category: null, isBase: false, color: colorCode };
      if (this.type == 'single') {
        if (index != -1) {
          // this.model[0] = obj;
          // return obj.color == colorCode;
          this.model.splice(0, 1, obj);
        }
        //  else {
        //   this.model[0] = obj;
        // }
      } else {
        var index = findIndex(this.model, (value) => {
          return value.color == colorCode;
        });
        if (index == -1) {
          this.model.push(obj);
        } else {
          // send toastr that color already exists
        }
      }
      this.$forceUpdate();
    },
    selectColor(obj) {
      each(this.model, (value) => {
        value.selected = false;
      });
      obj.selected = true;
      this.$forceUpdate();
    },
    removeColor() {
      var seIndex = 0;
      find(this.model, (value, index) => {
        if (value.selected) {
          return (seIndex = index);
        }
      });
      this.model.pop(seIndex);
      this.$forceUpdate();
    },
  },
  // computed: {
  //     showColor () {
  //       if (typeof this.color === 'string') return this.color

  //       return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
  //         color[key] = Number(this.color[key].toFixed(2))
  //         return color
  //       }, {}), null, 2)
  //     },

  // }
  mounted() {
    this.currentColor = {
      hex: '#FFF',
    };
  },
};
</script>

<style lang="scss">
.rounded-color {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}
.rounded-color.selected {
  border: 4px solid #ccc;
}
</style>
